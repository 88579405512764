import "./home-content.scss";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import image from "../../../assets/images/about-home.jpg";
// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// import greenCircle from "../../../assets/vectors/green-circle.svg";

const HomeContent: React.FC = () => {
  return (
    <section className={"o-home-content"}>
      <div className={"o-home-content__grid"}>
        <StaticImage
          src={"../../../assets/images/flowers.jpg"}
          alt={"Sobre mí"}
          width={918}
          height={1096}
          className={"a-image home__image-item is-sepia"}
        />
        <div className={"m-content-text home__text-item"}>
          <StaticImage
            src={"../../../assets/vectors/green-circle.svg"}
            className={"a-circle-rotate"}
            alt={"Círculo"}
          />
          <h2 className={"a-title a-line-left"}>
            Conociendo AlmaySer terapias florales
          </h2>
          <p className={"a-text"}>
            AlmaySer está formada por un equipo de terapeutas florales
            homologados como Practitioners BFRP (Bach Foundation Registered
            Practitioners) acreditados por el Centro Oficial Bach de Inglaterra,
            que tienen como objetivo ofrecer consultas florales online y
            promover los beneficios de las Flores de Bach. En un futuro,
            aumentaremos el equipo dando la bienvenida a otros profesionales que
            puedan ofrecer sus servicios Holísticos.
          </p>
          <p className={"a-text"}>
            AlmaySer es un hogar armónico y acogedor, que abraza a todo aquel
            que llega y le escucha atentamente sin dejar de prestar atención.
            AlmaySer es un espacio de comprensión, libre de prejuicios ni
            críticas, donde se reconoce a las personas por su situación
            presente.
          </p>
        </div>
      </div>
      <Link to="/sobre-mi/" className={"home__button-item"}>
        <button className="a-button is-block is-margin-auto">
          Saber más de mí
        </button>
      </Link>
    </section>
  );
};

export default HomeContent;
