import "./symptoms.scss";

import * as React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import greenCircle from "../../../assets/vectors/green-circle-small.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import yellowCircle from "../../../assets/vectors/yellow-circle-small.svg";

const Symptoms: React.FC = () => {
  return (
    <section className={"o-symptoms"}>
      <div className={"container"}>
        <h2 className={"a-line-center a-symptoms-title"}>
          Las Flores de Bach te pueden ayudar
        </h2>
        <div className={"grid-x3 grid-x3--break-device"}>
          <div className={"m-block"}>
            <img
              src={yellowCircle}
              className={"a-circle-rotate"}
              alt={"Círculo"}
            />
            <h3>Estrés</h3>
            <p className={"a-paragraph"}>
              Las flores de Bach son unas grandes aliadas para gestionar el
              estrés y recuperar nuestra serenidad. Uno de los principales
              causantes de trastornos emocionales que genera malestar en
              nuestras vidas y mantenido en el tiempo puede tener efectos
              negativos.
            </p>
          </div>
          <div className={"m-block m-block--to-bottom is-text-center"}>
            <img
              src={greenCircle}
              className={"a-circle-rotate a-circle-rotate--center"}
              alt={"Círculo"}
            />
            <h3>Ansiedad</h3>
            <p className={"a-paragraph"}>
              “Es intentar vivir mientras esperas en todo momento a que aparezca
              un león por la puerta”. Una de las mejores analogías que he leído
              sobre la ansiedad. El uso de Flores de Bach es muy eficaz para
              atenuar los síntomas y es ideal como complemento a otras terapias.
            </p>
          </div>
          <div className={"m-block m-block--right"}>
            <img
              src={yellowCircle}
              className={"a-circle-rotate a-circle-rotate--right"}
              alt={"Círculo"}
            />
            <h3>Muchas cosas más</h3>
            <p className={"a-paragraph"}>
              Como mejorar la autoestima, disminuir el sentimiento de culpa,
              superar la timidez, afrontar los cambios, aumentar la confianza,
              tener claridad mental, transitar duelos… En resumen, contribuyen a
              que recuperes tu bienestar emocional.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Symptoms;
