import "./services.scss";

import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import greenCircle from "../../../assets/vectors/green-circle.svg";
import ModalForm from "../../../components/ModalForm";
import { CitaOnline } from "../../../components/ModalForm/ContactForm/CitaOnline";

const Services: React.FC = () => {
  const [has_modal, setHasModal] = useState(false);
  return (
    <>
      {has_modal && (
        <ModalForm
          title="Consulta online"
          form={CitaOnline}
          is_active={setHasModal}
        />
      )}
      <section className={"o-services"}>
        <div className={"o-services__grid"}>
          <div className={"m-content-text"}>
            <div>
              <img
                src={greenCircle}
                className={"a-circle-rotate"}
                alt={"Círculo"}
              />
              <h2 className={"a-title a-line-right"}>
                Servicios de terapia floral en AlmaySer
              </h2>
            </div>
            <div className="m-text">
              <p>
                Desde AlmaySer queremos facilitar el acceso online a consultas
                florales con terapeutas acreditados por el Centro Bach y poner a
                disposición formulaciones florales personalizadas, entre otros
                productos derivados de las Flores de Bach. <br />
                La terapia floral que ofrece AlmaySer está basada en la
                filosofía del Dr. Edward Bach, utilizando su sistema de 38
                remedios florales. Las consultas florales se desarrollan
                mediante el método S.E.R., en el cual analizamos la situación de
                la persona, extrayendo las emociones que se han generado y
                seleccionando los remedios idóneos que le puedan ayudar mejor
              </p>
              <ul className={"m-list-check m-list-check--inside"}>
                <li className={"a-list-check__item"}>
                  {" "}
                  Con Practitioners acreditados por el Bach Centre
                </li>
                <li className={"a-list-check__item"}>
                  {" "}
                  Consulta floral 100% online con el método S.E.R.
                </li>
                <li className={"a-list-check__item"}>
                  {" "}
                  Formulación floral personalizada incluida
                </li>
              </ul>
            </div>
          </div>
          <StaticImage
            src={"../../../assets/images/bug-flower.jpeg"}
            alt={"flores"}
            className={"is-sepia is-device-hidden"}
            width={1008}
            height={1196}
          />
        </div>

        <button
          className="a-button is-block is-margin-auto"
          onClick={() => {
            setHasModal(true);
          }}
        >
          Pide tu consulta online
        </button>
      </section>
    </>
  );
};

export default Services;
