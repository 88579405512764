import React from "react";

import Layout from "../components/common/layout/layout";
import HomeContent from "../views/home/HomeContent/HomeContent";
import Services from "../views/home/Services/Services";
import Symptoms from "../views/home/Symptoms/Symptoms";
// import Brands from "../views/shared/Brands/Brands";
import Hero from "../views/shared/Hero/Hero";
import InstaFeed from "../components/InstaFeed/InstaFeed";
// import Reviews from "../components/Reviews/Reviews";
// import RemedyResume from "../views/home/RemedyResume/RemedyResume";
import SEO from "../components/common/SEO/SEO";
import {StaticImage} from "gatsby-plugin-image";
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";

const HomePage = () => {
    return (
        <Layout>
          <HomeMarkupSchema/>
            <SEO
                title="Terapia personal Flores de Bach. Gestión emocional AlmaySer"
                description="Haz florecer lo mejor que hay en ti con AlmaySer. Terapias florales personalizadas para tu bienestar emocional. Entra y comienza tu cambio."
            />
            <Hero title={"Haz florecer lo mejor que hay en ti con AlmaySer"}
                  description={["Reserva cita y encontremos juntas las flores que mejor te puedan ayudar."]}
                  cta_text={"Consulta online"} is_home={true}
                  >
              <StaticImage src={"../assets/images/hero.jpg"} alt={"Reserva tu cita en AlmaySer"} className={"is-cover is-sepia"}  width={960}
                height={680} />
            </Hero>
            <Symptoms/>
            <HomeContent/>
            {/*<Brands/>*/}
            <Services/>
            {/*<RemedyResume/>*/}
            {/*<Reviews/>*/}
            <InstaFeed/>
        </Layout>
    );
};

export default HomePage;
