import "./insta-feed.scss";

import { graphql, Link, useStaticQuery } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";

const InstaFeed: React.FC = () => {
  const data = useStaticQuery(graphql`
    query latestPost {
      allSanityPost(limit: 3, sort: { order: DESC, fields: publishedAt }) {
        nodes {
          slug {
            current
          }
          title
          mainImage {
            ...ImageWithPreview
          }
        }
      }
    }
  `);

  const post_render = [];
  data.allSanityPost.nodes.forEach((post, index) => {
    post_render.push(
      <Link
        to={`/${post.slug.current}/`}
        key={index}
        className={"instaFeed__item"}
      >
        <SanityImage
          {...post.mainImage}
          width={700}
          height={700}
          alt={post.title}
          className={"is-sepia"}
        />
        <span>{post.title}</span>
      </Link>
    );
  });

  return (
    <section>
      <div className={"o-insta-feed container"}>
        <h2 className={"a-line-center"}>Descubre la comunidad AlmaySer</h2>
        <div className={"o-insta-feed__grid"}>{post_render}</div>
      </div>
    </section>
  );
};

export default InstaFeed;
